import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { ExchangeRateAPI } from '@/services/exchange-rate';
import '@/filters';
import { firestorePlugin } from 'vuefire';
import Account from '@/models/account';
import firebase from '@/plugins/firebase';

Vue.use(firestorePlugin);
Vue.config.productionTip = false;
const requireComponent = require.context('./components', true, /[A-Z]\w+\.(vue|js)$/);
Vue.prototype.$exchangeRate = new ExchangeRateAPI();
Vue.prototype.$exchangeRate.connect();
Vue.prototype.$account = new Account();

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                ?.replace(/\.\w+$/, '')
        )
    );

    Vue.component(componentName, componentConfig.default || componentConfig);
});

router.beforeEach((to, from, next) => {
    const requiresGuest = !!to.matched.some(x => x.meta.requiresGuest);
    const isLoggedIn = !!firebase.auth().currentUser;
    Vue.prototype.$isSinglePage = !!to.matched.some(x => x.meta.singlePage);

    if (!isLoggedIn && !requiresGuest && to.path !== '/login') {
        next('/login');
    } else if (isLoggedIn && requiresGuest) {
        next('/');
    } else {
        next();
    }
});

let app: unknown;

firebase.auth().onAuthStateChanged(async user => {
    await (async function () {
        if (user !== null) {
            Vue.prototype.$account = new Account(user);
            await Vue.prototype.$account.init();
            console.log(Vue.prototype.$account);
        } else {
            console.log('hey'); //store.dispatch('LOGOUT');
        }
    })();

    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: h => h(App),
        }).$mount('#app');
        Vue.prototype.$exchangeRate.setApp(app);
    }
});
