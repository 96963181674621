var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"absolute":"","temporary":"","width":"350"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"pa-2"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"rounded":"","filled":"","placeholder":"Search all asset pairs"}})],1),_c('table',{staticStyle:{"width":"100%","border-spacing":"0px"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Asset")]),_c('th',[_vm._v("Buy")]),_c('th',[_vm._v("Sell")])])]),_c('tbody',_vm._l((_vm.rates),function(rate,i){return _c('tr',{key:("rate-" + i),staticClass:"exchange-rate-button",on:{"click":function($event){return _vm.changeCurrency(rate)}}},[_c('td',{staticClass:"pa-2"},[_vm._v(_vm._s(rate.from)+"/"+_vm._s(rate.to))]),_c('td',{staticClass:"pa-2"},[_c('span',{class:[
                            'font-weight-bold',
                            {
                                'red--text': rate.last.value > rate.value,
                                'green--text': rate.last.value < rate.value,
                            } ]},[_vm._v(" "+_vm._s(_vm._f("spreadBuy")(rate.value))+" ")])]),_c('td',{staticClass:"pa-2"},[_c('span',{class:[
                            'font-weight-bold',
                            {
                                'red--text': rate.last.value > rate.value,
                                'green--text': rate.last.value < rate.value,
                            } ]},[_vm._v(" "+_vm._s(_vm._f("spreadSell")(rate.value))+" ")])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }