import Vue from 'vue';
import moment from 'moment';
import firebase from '@/plugins/firebase';
import _ from 'lodash';

Vue.filter('usdFormat', (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value || 0);
});

Vue.filter('date', (value: firebase.firestore.Timestamp) => {
    if (!value) {
        return '';
    }
    return moment(value.toDate()).format('MM/DD/YYYY HH:mm:ss');
});

Vue.filter('dateShort', (value: firebase.firestore.Timestamp) => {
    if (!value) {
        return '';
    }
    return moment(value.toDate()).format('HH:mm:ss');
});

Vue.filter('alternateDate', (value: moment.Moment) => {
    if (!value) {
        return '';
    }
    return value.format('MMM DD, YYYY');
});

Vue.filter('currency', (value: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value || 0);
});

Vue.filter('uppercase', (value: string) => {
    return _.upperCase(value);
});

Vue.filter('spreadBuy', (value: number) => {
    const newValue = value * 0.006 + value;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(newValue || 0);
});

Vue.filter('spreadSell', (value: number) => {
    const newValue = value - value * 0.006;
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(newValue || 0);
});

Vue.filter('decimal', (value: number) => {
    if (!value) {
        return '';
    }
    return Math.round(value * 100) / 100;
});
