<script>
    import { Component, Mixins } from 'vue-property-decorator';
    import { Candlestick, mixins } from 'vue-chartjs-financial';
    const { reactiveProp } = mixins;

    @Component({
        name: 'BarChart',
        mixins: [reactiveProp],
        props: {
            chartData: {
                type: Object,
                default: null,
            },
            options: {
                type: Object,
                default: null,
            },
        },
    })
    export default class BarChartComponent extends Mixins(Candlestick) {
        mounted() {
            this.renderChart(this.chartData, this.options);
        }
    }
</script>
