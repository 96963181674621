

















































import BaseClass from '@/base';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class CryptoListDrawer extends BaseClass {
    drawer = true;
    interval = 3;
    refreshInterval = [
        {
            text: '1 Second',
            value: 1,
        },
        {
            text: '3 Seconds',
            value: 3,
        },
        {
            text: '5 Seconds',
            value: 5,
        },
        {
            text: '10 Seconds',
            value: 10,
        },
        {
            text: '60 Seconds',
            value: 60,
        },
    ];
    selectedCurrencyFrom = '';
    selectedCurrencyTo = '';
    rates: any[] = [];
    created() {
        this.$root.$on('TOGGLE_DRAWER', () => {
            this.drawer = !this.drawer;
        });
        this.selectedCurrencyFrom = this.$exchangeRate.rates[0].from;
        this.selectedCurrencyTo = this.$exchangeRate.rates[0].to;
        this.rates = this.$exchangeRate.rates;
    }

    @Watch('interval')
    onIntervalUpdated(interval: number) {
        this.$exchangeRate.interval = interval;
    }

    changeCurrency(rate: { from: string; to: string; value: number }) {
        this.selectedCurrencyFrom = rate.from;
        this.selectedCurrencyTo = rate.to;
        this.$root.$emit('CHANGE_CURRENCY', rate);
        this.drawer = false;
    }
}
