import firebase from '@/plugins/firebase';
import store from '@/store';

export default class Account {
    id!: string;
    user!: firebase.User;
    isFullAdmin = false;

    constructor(user?: firebase.User) {
        if (user) {
            this.id = user.uid;
            this.user = user;
        }
    }

    get isLoggedIn(): boolean {
        return !!firebase.auth().currentUser;
    }

    get userName(): string {
        return store.getters['me']?.userName || '';
    }

    get firstName(): string {
        return store.getters['me']?.firstName || '';
    }

    get lastName(): string {
        return store.getters['me']?.lastName || '';
    }

    get phoneNumber(): string {
        return store.getters['me']?.phoneNumber || '';
    }

    get photoURL(): string | null | undefined {
        return firebase.auth()?.currentUser?.photoURL;
    }

    get country(): string {
        return store.getters['me']?.country || '';
    }

    get email(): string {
        return store.getters['me']?.email || this.user?.email || '';
    }

    get balance(): number {
        return store.getters['me']?.balance || 0;
    }

    public async init(): Promise<void> {
        await Promise.all([store.dispatch('bindMe', this.id), store.dispatch('trades/bindTradesRef', this.id)]);
    }

    public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    public async loginWithToken(token: string): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithCustomToken(token);
    }
    public async logout(): Promise<void> {
        return firebase.auth().signOut();
    }
}
