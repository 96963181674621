
















import BaseClass from '@/base';
import { Component } from 'vue-property-decorator';

@Component
export default class App extends BaseClass {
    get theme() {
        return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
}
