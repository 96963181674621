import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/DashboardView.vue'),
        meta: {
            requiresAuth: true,
            singlePage: false,
        },
    },
    {
        path: '/account',
        name: 'account',
        meta: {
            requiresAuth: true,
            singlePage: false,
        },
        component: () => import('@/views/AccountView.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            requiresAuth: false,
            singlePage: true,
            requiresGuest: true,
        },
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/AccountView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/trades',
        name: 'Trades',
        component: () => import('@/views/TradesView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: () => import('@/views/TransactionsView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/deposits',
        name: 'Deposits',
        component: () => import('@/views/DepositView.vue'),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/withdrawals',
        name: 'Withdrawals',
        component: () => import('@/views/WithdrawalsView.vue'),
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
