import config from '@/../config.json';
import { Vue } from 'vue-property-decorator';

type Rate = {
    from: string;
    to: string;
    value: number;
    last: {
        value: number;
    };
};
export class ExchangeRateAPI {
    client!: WebSocket;
    app!: Vue;
    interval = 3;
    rates: Array<Rate> = [
        {
            from: 'BTC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'YCC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BCH',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'DOT',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETH',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BTT',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'TRX',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BSV',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'USDC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'IOTA',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'LTC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'OMG',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'QTUM',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XMR',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ZEC',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'DAI',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'UNI',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'VET',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XRP',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'XTZ',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ATOM',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BNB',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'EOS',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BTG',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        // EUR
        {
            from: 'BTC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'YCC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BCH',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'DOT',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETH',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BTT',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'TRX',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BSV',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'USDC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'IOTA',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'IOTA',
            to: 'USD',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'LTC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'OMG',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'QTUM',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XMR',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ZEC',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'DAI',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'UNI',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'VET',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XRP',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'XTZ',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ATOM',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BNB',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'EOS',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BTG',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },

        // GBP
        {
            from: 'BTC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'YCC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BCH',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'DOT',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETH',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'ETC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BTT',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'TRX',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'BSV',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'USDC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'IOTA',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'IOTA',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'LTC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'OMG',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'QTUM',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XMR',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ZEC',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'DAI',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'UNI',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'VET',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'XRP',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },
        {
            from: 'XTZ',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'ATOM',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BNB',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'EOS',
            to: 'GBP',
            value: 0,
            last: {
                value: 0,
            },
        },

        {
            from: 'BTG',
            to: 'EUR',
            value: 0,
            last: {
                value: 0,
            },
        },
    ];

    constructor() {
        this.client = new WebSocket(config.exchangeRate.url);
    }

    public setApp(app: Vue): void {
        this.app = app;
    }

    public getRate(from: string, to: string): Rate | '' {
        const rate = this.rates.find(i => i.from === from && i.to === to);
        if (!rate) {
            return '';
        }

        return rate;
    }

    public connect(): void {
        this.client.onopen = () => {
            this.send({
                type: 'hello',
                apikey: config.exchangeRate.apiKey,
                heartbeat: false,
                subscribe_data_type: ['exrate'],
                subscribe_filter_asset_id: this.rates.map(i => i.from),
                subscribe_filter_exchange_id: this.rates.map(i => i.to),
            });
        };

        this.client.onmessage = e => {
            if (!this.app) {
                return;
            }

            if (!e.data) {
                return;
            }

            const seconds = Math.round(Date.now() / 1000);
            if (seconds % this.interval !== 0) {
                return;
            }

            const data = JSON.parse(e.data);

            if (!data.asset_id_base || !data.asset_id_quote) {
                return;
            }

            let index: null | number = null;

            for (let i = 0; i < this.rates.length; i++) {
                if (this.rates[i].from === data.asset_id_base && this.rates[i].to === data.asset_id_quote) {
                    index = i;
                    break;
                }
            }

            if (index !== null) {
                const rateToUpdate = this.rates[index];
                rateToUpdate.last.value = rateToUpdate.value;
                rateToUpdate.value = data.rate;

                this.app.$emit('EXRATE_UPDATED');
            } else {
                // check reverse
                for (let i = 0; i < this.rates.length; i++) {
                    if (this.rates[i].from === data.asset_id_quote && this.rates[i].to === data.asset_id_base) {
                        index = i;
                        break;
                    }
                }

                if (index !== null) {
                    const rateToUpdate = this.rates[index];
                    rateToUpdate.last.value = rateToUpdate.value;
                    rateToUpdate.value = 1 / data.rate;

                    this.app.$emit('EXRATE_UPDATED');
                }
            }
        };
    }

    public send(message: Record<string, unknown>): void {
        return this.client.send(JSON.stringify(message));
    }
}
