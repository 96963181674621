import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/remote-config';
import * as config from '@/../config.json';

firebase.initializeApp(config.firebase);
firebase.remoteConfig().settings = {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 3600,
};

firebase.remoteConfig().fetchAndActivate();

if (config.functionsLocation) {
    firebase.app().functions(config.functionsLocation);
} else {
    firebase.functions();
}

export default firebase;
