
























































import BaseClass from '@/base';
import { Component } from 'vue-property-decorator';

@Component
export default class AppbarComponent extends BaseClass {
    drawer = false;

    private async logout(): Promise<void> {
        await this.$account.logout();
        window.location.href = '/';
    }
}
