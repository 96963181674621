import { Vue } from 'vue-property-decorator';
import store from '@/store';

import firebase from '@/plugins/firebase';
declare type SnackType = 'error' | 'success' | undefined;

export default class BaseClass extends Vue {
    $refs!: {
        form: HTMLFormElement;
        apiKey: HTMLFormElement;
    };
    currentTrades: {
        amount: number;
        asset: {
            from: string;
            to: string;
        };
        leverage: number;
        status: string;
        stopLoss: number;
        takeProfit: number;
        time: firebase.firestore.Timestamp;
        type: 'Buy' | 'Sell';
        uid: string;
    }[] = [];
    public loading = false;

    get trades() {
        return this.$store.getters['trades/trades'];
    }

    snackMessage(message: string, type?: SnackType): void {
        store.dispatch('snackbar/setSnack', {
            message: message,
            type: type,
        });
    }
}
