









import BaseClass from '@/base';
import { Component } from 'vue-property-decorator';

@Component
export default class SnackbarComponent extends BaseClass {
    show = false;
    message = '';

    created() {
        this.$store.watch(
            state => state.snackbar.message,
            () => {
                const msg = this.$store.state.snackbar.message;
                if (msg) {
                    this.show = true;
                    this.message = msg;
                    this.$store.dispatch('snackbar/setSnack', '');
                }
            }
        );
    }
}
