import { Module } from 'vuex';
import firebase from 'firebase';
import { vuexfireMutations, firestoreAction } from 'vuexfire';

interface TradeState {
    trades: {
        amount: number;
        asset: {
            from: string;
            to: string;
        };
        leverage: number;
        status: string;
        stopLoss: number;
        takeProfit: number;
        time: firebase.firestore.Timestamp;
        type: 'Buy' | 'Sell';
        uid: string;
    }[];
}

export default {
    state: {
        trades: [],
    },
    mutations: vuexfireMutations,
    getters: {
        trades: state => state.trades,
    },
    actions: {
        bindTradesRef: firestoreAction(async ({ bindFirestoreRef }, uid: string) => {
            return bindFirestoreRef('trades', firebase.firestore().collection('trades').where('uid', '==', uid));
        }),
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
} as Module<TradeState, {}>;
